.skills-overview-first {
    background-color: #f8e4cc;
    border-radius: 0 10px 0 0;
    padding: 20px;
}

.skills-overview {
    background-color: #f8e4cc;
    border-radius: 0 0 10px 0;
    padding: 20px;
}

.skills-overview-flat {
    background-color: #f8e4cc ;
    padding: 20px;
}

.custom-image {
    width: 35px;
    height: auto;
}

.hovered-skill {
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition pour les transformations et l'ombre */
    list-style: none; /* Retirer les puces par défaut */
}

.hovered-skill:hover {
    transform: scale(1.05); /* Agrandir l'élément légèrement */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Ajout d'une ombre légère */
}

.Message_Programming{
    width: 45px;
    height: auto;
}

.Icon_excitement{
    position: absolute;
    top: -33px;
    left: -40px;
    overflow: auto;
}

@media (max-width: 768px) {
    .Icon_excitement {
        display: none;
    }
}

@media (max-width: 991px) {
    .removable-column-ipadSize {
        display: none;
    }
}

.Comet-image{
    position: absolute;
    right: -50px;
    bottom: -80px;
    width: 250px;
    height: auto;
}