.responsive-img {
    width: 200px;
    height: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

@media (max-width: 1020px) {
    .img-hidden {
        display: none;
    }
}