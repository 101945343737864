@keyframes fadeInImage {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.form-container {
    background-color: #e7d1b6;
    padding: 40px;
    border-radius: 10px;
    max-width: 800px;
    height: 550px;
    margin: auto;
    color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.form {
    display: flex;
    flex-direction: column;
}

.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.form-input {
    background-color: #faeaea;
    border: 1px solid #c2a887;
    color: #464646;
    padding: 12px;
    width: 48%;
    border-radius: 5px;
    font-size: 16px;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.form-input::placeholder {
    color: #575757;
}

.form-input:focus {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(46, 103, 248, 0.6);
}

.message {
    width: 100%;
    height: 120px;
    resize: none;
}

.send-button {
    background-color: #525252;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease-in-out;
}

.send-button:hover {
    background-color: #464646;
    transform: scale(1.1);
}

.send-button:active {
    transform: scale(0.95);
}

.maven-font {
    font-family: 'Maven Pro', sans-serif;
}

@keyframes fadeInText {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.prev-page {
    color: #464646;
    font-size: 3rem;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #464646;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    padding-bottom: 13px;
    padding-right: 9px;
}

.prev-page:hover {
    transform: scale(1.1);
    background-color: #464646;
    color: white;
}

.animate-img{
    animation: fadeInImage 1s ease-in-out;
}