/*.image-recommendation {
    width: 820px !important;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}*/

.image-recommendation {
    width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.FireworksTR-img {
    position: absolute;
    width: 250px;
    height: auto;
    top: -130px;
    right: -130px;
}

.recommendation-container {
    position: relative;
    width: 100%;
    max-width: 820px !important;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px) {
    .FireworksTR-img {
        display: none;
    }
}