.font-islands {
    font-family: 'Island Moments', serif;
    font-size: 128px;
    color: #464646;
}

@media (max-width: 768px) {
    .font-islands {
        font-size: 120px;
    }
}

.font-inter {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: bold;
    color: #464646;
}

#icon-terminal {
    width: 40px;
    height: auto;
    vertical-align: middle;
}

.photo {
    position: relative;
    height: auto;
    border: black 1px solid;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.1s ease-out; /* Transition pour un mouvement fluide */
}

.photo-1, .photo-2, .photo-3 {
    transition: transform 0.1s ease-out; /* Transition pour un mouvement fluide */
}


.photo-1 {
    left: -13%;
    z-index: 3; /* Mettre au premier plan */
    width: 300px;
    border: none;
}

.photo-2 {
    top: 400px;
    right: 40%;
    z-index: 2; /* Mettre derrière la première */
    width: 175px;
}

.photo-3 {
    top: -250px;
    right: 55%;
    z-index: 1; /* Mettre en arrière-plan */
    width: 300px;
    border: none;
}

.image-sparkle {
    width: 50px;
    height: auto;
}

.btn-custom {
    background-color: #464646;
    color: white;
    border: none;
    border-radius: 10px;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.btn-custom:hover {
    background-color: #3a3a3a;
    transform: scale(1.1);
}

.image-icon_next {
    width: 60px !important;
    height: auto !important;
    animation: floatVertical 2s ease-in-out infinite;
}

@keyframes floatVertical {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px); /* Vous pouvez ajuster la distance de l'oscillation */
    }
    100% {
        transform: translateY(0);
    }
}

.row-icon-next {
    justify-content: center;
    margin-bottom: 50px;
}

@media (max-width: 1700px) {
    .photo-1, .photo-2, .photo-3 {
        margin: 10px 0;
        right: 5%;
    }
    .photo-1 {
        z-index: 3;
    }
    .photo-2 {
        z-index: 1;
    }
    .photo-3 {
        z-index: 2;
    }

}

@media (max-width: 1220px) {
    .photo-container {
        display: none;
    }
    .photo {
        display: none;
    }
    .row-icon-next {
        justify-content: center;
        margin-bottom: 70px;
        padding-top: 60px;
    }
}

@media (max-width: 900px) {
    .photo {
        display: none;
    }
    .row-icon-next{
        padding-top: 70px;
        padding-bottom: 50px;
    }
}

@keyframes floatHorizontal {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(15px); /* Ajustez la distance de flottement */
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes floatVertical {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px); /* Ajustez la distance de flottement */
    }
    100% {
        transform: translateY(0);
    }
}

@media (min-width: 1921px) {
    .photo-1 {
        animation: floatHorizontal 4s ease-in-out infinite;
        top : 10px;
        right: -50px;
    }

    .photo-2 {
        animation: floatVertical 6s ease-in-out infinite;
        top : 150px;
        right: -70px;
    }

    .photo-3 {
        animation: floatHorizontal 5s ease-in-out infinite reverse;
        top: 100px;
        right : 750px;
    }

    .row-icon-next {
        margin-bottom: 50px;
        padding-top: 150px;
    }
}