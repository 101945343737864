.custom-Flag_Thanks {
    position: relative;
    width: 400px !important;
    height: auto;
    right: 12%;
}

.horizontal-bar {
    width: 60%;
    height: 1px;
    background-color: #464646;
    border-radius: 10px;
    position: absolute;
    top: 60%;
    left: 15%;
    transform: translateY(-50%);
    z-index: 1;
}

.linkedin-link {
    color: #464646;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    transition: color 0.3s;
}

.linkedin-link:hover {
    color: #0077b5;
}

.linkedin-link .fa-linkedin {
    font-size: 20px;
}

.email-copy-container {
    position: relative;
    display: inline-block;
}

.email-text {
    cursor: pointer;
    color: #007BFF;
    text-decoration: underline;
}

.email-text:hover {
    color: #0056b3;
}

.tooltip {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #464646;
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    opacity: 0;
    animation: fadeInOut 2s forwards;
    z-index: 100;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
