.image-Cup_Shining {
    width: 80px;
    height: auto;
}

.custom-card-text{
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: #D8D8D8;
    border-radius: 0 10px 10px 0;
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 575px;
}

.custom-card-transition-text{
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: #D8D8D8;
    border-radius: 10px 0 0 10px;
}

.images-projects{
    width: 100%;
    max-width: 800px;
    height: auto;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    transform: scale(1.2);
}

.images-projects:hover {
    transform: scale(1.25);
    background-color: #464646;
    color: white;
}

@media (max-width: 1024px) {
    .images-projects {
        transform: scale(1);
    }

    .images-projects:hover {
        transform: scale(1.05);
    }

    .custom-card-text{
        min-height: auto;
    }
}

.projects-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev-btn, .next-btn {
    color: #464646;
    font-size: 2rem;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 2px solid #464646;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    padding-bottom: 7px;
    object-fit: cover;
}

.prev-btn:hover, .next-btn:hover {
    transform: scale(1.1);
    background-color: #464646;
    color: white;
}

.progress-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}


.progress-bar {
    width: 50px;
    height: 10px;
    background-color: white;
    margin: 0 15px;
    border-radius: 5px;
    border: 1px solid black;
}

.progress-bar.filled {
    background-color: #464646;
    border-color: black;
}

.progress-bar.empty {
    background-color: #EAEAEA;
    border-color: black;
}

.endPage-margin {
    margin-bottom: 120px;
}

.custom-btn-github{
    background-color: #464646;
    color: white !important;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 180px;
    height: 40px;
    padding: 10px;
    display: flex;
    justify-content: center; /* Centrer horizontalement */
    align-items: center; /* Centrer verticalement */
    gap: 10px; /* Espace entre l'icône et le texte */
    text-decoration: none;
}

.custom-btn-github:hover {
    transform: scale(1.05);
}

.custom-btn-github i {
    font-size: 1.5rem; /* Taille de l'icône */
}


@media (min-aspect-ratio: 21/9) {
    .custom-card-text {
        padding: 30px;
        min-height: 575px;
    }
}

.technologies-list {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap; /* Permet d'avoir une nouvelle ligne si les éléments ne tiennent pas dans l'espace */
    gap: 10px; /* Ajoute un espacement entre les technologies */
}

.technology-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.icon {
    margin-right: 8px;
    font-size: 1.5rem;
    color: #333;
}

/* Style du tooltip */
.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    white-space: nowrap;
}

.Fireworks-img{
    position : absolute;
    left: 125px;
    bottom: -45px;
}