.image-Man_Accomplished {
    width: 80px;
    height: auto
}

.row-overlay {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 20px;
    text-align: center;
}

.image-About_BG {
    width: 1050px !important;
    height: 250px;
}

.text{
    font-size: 15px;
    font-weight: bold;
    width: 900px;
}

@media (max-width: 920px) {
    .text{
        font-size: 14px;
        font-weight: bold;
        text-align: start;
    }
}
